
import Vue from 'vue'
import MultiMallPromotionView from '../../views/event/MultiMallPromotion.vue'
export default Vue.extend({
  name: 'MultiMallPromotion',
  components: { MultiMallPromotionView },
  data() {
    return {
      scrollLocation: 0,
    }
  },
})
