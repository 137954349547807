var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('header',{staticClass:"fixed promotion-top fixed-content-area z-index-10",class:`${_setup.headerAnimClass}`},[_c('div',{staticClass:"fill-width white d-flex align-center",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex justify-space-between fill-width align-center px-10"},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":_setup.onClickPrevIcon}},[_vm._v(" $vuetify.icons.prevIcon ")]),_c('span',{staticClass:"oneLine rixgo-extrabold align-center gray900--text font-g16-24"},[_vm._v(_vm._s(_setup.multiPromotionGroup?.title))]),_c('div',{staticClass:"pb-1",on:{"click":_setup.onClickShareIcon}},[_c('v-icon',[_vm._v("$vuetify.icons.shareIcon")])],1)],1)])]),(!_setup.multiPromotionGroup)?_c('div',[_c('v-skeleton-loader',{staticClass:"relative fill-width",attrs:{"id":"category_exhibition_main_image","height":_vm.$isMobile() ? '420' : '700',"type":"image"}})],1):_c('div',{staticClass:"category-header-margin-top pb-64"},[(_setup.multiPromotionGroup.isClosed)?_c('div',{staticClass:"fill-width pt-20 pb-20",staticStyle:{"background":"black"}},[_vm._m(0)]):_vm._e(),(
        _setup.multiPromotionGroup.timerInfo.isUse && !_setup.multiPromotionGroup.isClosed
      )?_c('div',{staticClass:"fill-width py-12 oneLine"},[_c('div',{staticClass:"d-flex justify-center align-center pb-10"},[_c('div',{staticClass:"font-g16-24 campton-medium gray900--text"},[_vm._v(" "+_vm._s(_setup.multiPromotionGroup.timerInfo.title)+" ")])]),_c(_setup.FlipCountdown,{attrs:{"deadline":_setup.multiPromotionGroup.endDt,"showDays":!_setup.multiPromotionGroup.isClosed},on:{"timeElapsed":_setup.timeElapsedHandler}})],1):_vm._e(),_vm._l((_setup.multiPromotionGroup.topImageUrlList),function(item){return _c('div',{key:item,staticClass:"fill-width"},[_c('v-img',{attrs:{"src":item,"height":"auto","eager":""},on:{"load":_setup.onLoadTopImage}})],1)}),(_setup.multiPromotionGroup.existsArrivalGuarantee)?_c('div',{staticClass:"mx-10 mt-10 d-flex align-center flex-row-reverse"},[_c(_setup.ToolTipButton,{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
          handler: _setup.clickOutsideTooltip,
          include: _setup.include,
        }),expression:"{\n          handler: clickOutsideTooltip,\n          include: include,\n        }"}],on:{"clickHandler":_setup.onOffTooltip}}),_c(_setup.ToolTip,{staticClass:"included",attrs:{"show":_setup.showToolTip},on:{"close":function($event){_setup.showToolTip = false},"onClickHandler":_setup.setScroll}})],1):_vm._e(),(_setup.multiPromotionGroup.flagshipItem.searchProductList.length > 0)?_c('div',{staticClass:"fill-width pb-20",style:(`background-color: ${_setup.multiPromotionGroup.flagshipItem.backgroundColor}`)},[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"}},[_c(_setup.VueSlickCarousel,_vm._b({ref:"vueSlickCarousel",attrs:{"arrows":false},on:{"afterChange":_setup.onAfterChangeCarousel}},'VueSlickCarousel',_setup.carouselOption,false),_vm._l((_setup.multiPromotionGroup.flagshipItem
              .searchProductList),function(itemList,index){return _c('div',{key:index,staticClass:"pt-10"},[(
                itemList.isArrivalGuarantee && _setup.carouselTargetImage === index
              )?_c('v-img',{staticClass:"naver-delivery",attrs:{"src":require("../../../assets/icons/n-guaranteed2.svg"),"width":"64","height":"20"}}):_vm._e(),_c(_setup.ProductCardImage,{attrs:{"eventMallType":"single","mallType":"multi","rounded":"rounded-lg","imageIndex":index,"targetImage":_setup.carouselTargetImage,"product":itemList,"aspectRatio":228 / 308,"gtagName":`collabo_${_setup.no}_rolling_${_vm.$numbering(index + 1)}`},on:{"onClickBlurImage":_setup.onClickBlurImage},nativeOn:{"click":function($event){return _setup.setPromotionMultiMallInitData.apply(null, arguments)}}})],1)}),0),_c('div',{staticClass:"d-flex justify-center align-center pt-8"},[_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.vueSlickCarousel.prev()}}},[_vm._v("$vuetify.icons.gray500PrevVector")]),_c('div',{staticClass:"font-g12-18 gray500--text campton-book text-center pt-1",staticStyle:{"width":"64px"}},[_vm._v(" "+_vm._s(_setup.carouselProgress + 1 >= 10 ? _setup.carouselProgress + 1 : '0' + (_setup.carouselProgress + 1))+" / "+_vm._s(_setup.multiPromotionGroup.flagshipItem.searchProductList.length >= 10 ? _setup.multiPromotionGroup.flagshipItem.searchProductList.length : '0' + _setup.multiPromotionGroup.flagshipItem.searchProductList.length)+" ")]),_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.vueSlickCarousel.next()}}},[_vm._v("$vuetify.icons.gray500NextVector")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"sticky-bar",class:_setup.headerAnimClassForSticky},[_c('v-tabs',{attrs:{"background-color":"default","color":"default_t","grow":"","fixed-tabs":"","height":"47","eager":"","touchless":""}},[_c('div',{staticClass:"fill-width d-flex border-bottom-gray"},_vm._l((_setup.multiPromotionGroup.multiShopInfoList),function(item,index){return _c('div',{key:item.lunaId,class:`fill-width d-flex justify-center align-center rixgo-bold font-g14-20 ${
              _setup.targetMallIndex === index ? 'black--text' : 'gray500--text'
            }`,style:(`border-top: 4px solid white; border-bottom: 4px solid white; ${
              _setup.targetMallIndex === index
                ? 'border-bottom: 4px solid black'
                : ''
            }`),on:{"click":function($event){return _setup.handleTabChange(index)}}},[_c('div',{staticClass:"fill-width text-center",style:(`${
                index === 0 ? '' : 'border-left: 1px solid #eaebed;'
              } height: 45%;`)},[_vm._v(" "+_vm._s(item.shopName)+" ")])])}),0)])],1),_vm._l((_setup.multiPromotionGroup.multiShopInfoList),function(item,multiShopInfoIndex){return _c('div',{key:item.shopId,staticClass:"mt-10"},[_c('div',{ref:(el) => {
            _setup.shopRootElements[multiShopInfoIndex] = el
          },refInFor:true,staticClass:"pa-10 pb-10 pt-27"},[(item.imageUrl)?_c('v-img',{staticClass:"rounded-lg",attrs:{"eager":"","src":item.imageUrl,"height":"auto","aspect-ratio":603 / 626}}):_vm._e(),_c('div',{staticClass:"d-flex align-center pt-8 pb-10"},[_c('div',{staticClass:"fill-width d-flex align-center",staticStyle:{"width":"73%"},on:{"click":function($event){return _setup.moveToStore(item.shopId, multiShopInfoIndex)}}},[_c('v-img',{staticClass:"rounded-circle",attrs:{"eager":"","src":item.icon,"aspect-ratio":50 / 50,"height":"50","max-width":"50","width":"50"}}),_c('div',{staticClass:"txt_line pl-5"},[_c('div',{staticClass:"d-flex pb-2"},[_c('div',{staticClass:"rixgo-semibold font-g14-20 pr-4"},[_vm._v(" "+_vm._s(item.shopName)+" ")]),_vm._l((item.ages),function(ages,index){return _c('div',{key:index,staticClass:"pr-2"},[_c('div',{staticClass:"fill-width fill-height d-flex align-center rixgo-regular font-g10-18 gray500--text rounded pl-2 pr-2",staticStyle:{"background-color":"#eaebed"}},[_vm._v(" "+_vm._s(ages)+" ")])])})],2),_c('div',{staticClass:"txt_line rixgo-regular font-g12-18 pr-8"},[_vm._v(" "+_vm._s(item.description)+" ")])])],1),_c('div',{staticClass:"text-align pt-1",staticStyle:{"width":"27%","border-left":"1px solid #eaebed"}},[_c('div',{staticClass:"d-flex justify-center align-center pb-2",on:{"click":function($event){return _setup.toggleSubscribe(
                  `${item.shopId}`,
                  item.shopName,
                  item.isSubscribed,
                  multiShopInfoIndex
                )}}},[_c('v-img',{attrs:{"src":item.isSubscribed
                    ? require(`./../../../assets/images/subscribeMultiMall.svg`)
                    : require(`./../../../assets/images/unSubscribeMultiMall.svg`),"max-height":"18","max-width":"14"}})],1),_c('div',{staticClass:"d-flex justify-center align-center campton-book gray500--text font-g12-18 gray500--text"},[_vm._v(" "+_vm._s(_vm._f("handleTransform")(_setup.multiPromotionGroup?.multiShopInfoList[multiShopInfoIndex] ?.subscribeCount > 99999 ? _setup.multiPromotionGroup?.multiShopInfoList[multiShopInfoIndex] ?.subscribeCountDisplay : _setup.multiPromotionGroup?.multiShopInfoList[multiShopInfoIndex] ?.subscribeCount))+" ")])])]),_vm._l((item.categoryList),function(categoryList,categoryIndex){return _c('div',{key:categoryIndex,staticClass:"d-flex flex-column"},[_c('div',{staticClass:"rixgo-bold font-g16-24 pt-20 pb-8"},[_vm._v(" "+_vm._s(categoryList.title)+" ")]),_c('div',{staticClass:"product-y-grid-two-col"},_vm._l((categoryList.searchProductList.slice(
                0,
                categoryList.limit
              )),function(productItem,productItemIndex){return _c('div',{key:productItem.productNo},[_c(_setup.ProductCardY,{attrs:{"product":productItem,"isMallNameVisible":false,"naverDelivery":productItem.isArrivalGuarantee,"useSoldOut":true,"gtagName":`collabo_${_setup.no}_cate${_vm.$numbering(
                  categoryIndex + 1
                )}_${_vm.$numbering(productItemIndex + 1)}`},on:{"isLikeChange":(yn) => _setup.isLikeChange(yn, categoryIndex, productItemIndex)},nativeOn:{"click":function($event){return _setup.setPromotionMultiMallInitData.apply(null, arguments)}}})],1)}),0),(
              categoryList.searchProductList.length > 20 &&
              categoryList.searchProductList.slice(0, categoryList.limit)
                .length <= 20
            )?_c('div',{staticClass:"pt-5 pb-5"},[_c('div',{staticClass:"fill-width d-flex align-center justify-center",staticStyle:{"border":"1px solid #003399","height":"47px","border-radius":"4px"},on:{"click":function($event){return _setup.viewMore(item.shopId, categoryIndex)}}},[_c('div',{staticClass:"font-g13-18 rixgo-black blue--text"},[_vm._v(" "+_vm._s(categoryList.title)+" ")]),_c('div',{staticClass:"font-g13-18 rixgo-regular blue--text pl-2"},[_vm._v(" 더보기 "),_c('v-icon',{staticClass:"pl-3 pb-1",attrs:{"size":"16"}},[_vm._v("$vuetify.icons.downIcon")])],1)])]):_vm._e()])})],2)])}),_c(_setup.Sharing,{attrs:{"shareItem":{
        title: _setup.multiPromotionGroup?.shareInfo.title,
        description: '',
        imageUrl: _setup.multiPromotionGroup?.shareInfo.thumbnail,
        url: _setup.shareUrl,
        appUrl: '',
      },"content":'',"show":_setup.showShare,"url":_setup.shareUrl,"store":_setup.multiPromotionGroup?.shareInfo.title,"sheetTitle":"기획전"},on:{"onClose":_setup.onCloseShare}}),(_setup.multiPromotionGroup.bottomBannerList)?_c('div',{staticClass:"font-g16-24 pa-10 rixgo-bold"},[_vm._v(" 다른 기획전 ")]):_vm._e(),(_setup.multiPromotionGroup.bottomBannerList)?_c('div',{staticClass:"pl-10 pr-10"},_vm._l((_setup.multiPromotionGroup.bottomBannerList),function(item,index){return _c('div',{key:index},[(item.icon)?_c('v-img',{staticClass:"mb-8 border-radius-8",attrs:{"height":"auto","src":item.icon,"aspect-ratio":335 / 98,"lazy-src":require('@/assets/images/img_back.png')},on:{"click":function($event){return _setup.goExhibitionList(item.link, index)}}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"pt-20 pl-10 pr-10"},[_c(_setup.CellookButton,{attrs:{"buttonTitle":`MD's Pick 보기`,"background-color":`#003399`,"buttonTitleTextClass":`font-g14-20 rixgo-bold`,"text-color":`white`},on:{"click":function($event){return _setup.goMainMDSPick()}}})],1)],2),_c(_setup.Alert,{attrs:{"show":_setup.alertInfo.show,"content":_setup.alertInfo.message},on:{"onClose":function($event){_setup.alertInfo = { show: false, message: '' }}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white--text"},[_c('div',{staticClass:"d-flex justify-center font-g14-20 rixgo-bold pb-2"},[_vm._v(" 종료된 기획전입니다. ")]),_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" 할인율과 혜택이 적용되지 않을 수 있습니다. ")])])
}]

export { render, staticRenderFns }